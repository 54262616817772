window.addEventListener("scroll", function () {
  // ヘッダーを変数の中に格納する
  const header = document.querySelector("header");
  // 100px以上スクロールしたらヘッダーに「scroll-nav」クラスをつける
  header.classList.toggle("scroll-nav", window.scrollY > 100);

  //スライドインのアニメーション
  var elements = document.querySelectorAll('.slidein');

  elements.forEach(function(element) {
      var elemPos = element.offsetTop,
          scroll = window.scrollY,
          windowHeight = window.innerHeight;

      if (scroll > elemPos - windowHeight + 150) {
          element.classList.add('scrollin');
      }
  });
});