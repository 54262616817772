document.addEventListener('DOMContentLoaded', function () {
  var slider = document.querySelector('.idx-cards');

  if (slider) {
    $(slider).slick({
      arrows: false,
      autoplay: false,
      adaptiveHeight: true,
      dots: true,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 896,
          settings: {
          slidesToShow: 2,
          centerMode: true,// 前後スライドを部分表示
          centerPadding: '5%',
          },
        },
        {
          breakpoint: 480,
          settings: {
          slidesToShow: 1,
          centerMode: true,// 前後スライドを部分表示
          centerPadding: '10%',
          },
        },
      ],
    });
  }

});
